.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html { 
  background-color: lightblue;
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(234,249,249,0.67) 0.1%, rgba(239,249,251,0.63) 90.1% );
  }

.control-inferior { 
  margin: 20px 20px 20px 20px;
  width: 600px;
}

.tools-fixed { 
  position: sticky;
  top: 0;
  margin-left: 75%;
  margin-right: 0%;
  z-index: 9;
}

.tabla-datos { 
  width: 1000px;
}

.selector-tablas { 
  width: 1000px;
}