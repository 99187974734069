.regla {
    z-index: 1;
    width: 200px;
    height: 30px;
    background-color: rgb(26, 44, 70);
    border-style: solid;
    border-color: black;
    border-width: 1px;
    position: sticky;
    top: 0;
    left: 0;
}

.arrowButton {
    height: 30px;
    width: 30px;
    float: right;
}

.hidden {
    display: 'none';
}

.line {
    z-index: 2;
    position: absolute;
    top: 0px;
    height: 15px;
    width: 2px;
    background-color: rgb(207, 194, 194);
}

.rulerLabel {
    z-index: 2;
    position: absolute;
    top: 10px;
    color: white;
    text-align: left;
    font-size: 12px;
}