.Calle, .CalleFixed { 
    width: 200px;
    height: 100px;
    background-color: rgb(150, 140, 140);
    border-color: black;
    border-width: 1px;
    border-style: solid;
    touch-action: none;
    box-sizing: border-box;
    color: black;
    z-index: -1;
    font-size: 11px;
    font-weight: bold;
}


.almacen {
    width: 1200px;
    height: 600px;
    border-color: black;
    border-width: 2px;
    border-style: solid;
    margin: 20px 20px 20px 20px;
    overflow: scroll;
}

.canvas { 
    border-color: rgb(66, 62, 62);
    border-width: 2px;
    border-style: dashed;
    margin: 20px 20px 20px 20px;
  }
