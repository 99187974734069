.Columna, .ColumnaFixed { 
    width: 200px;
    height: 100px;
    background-color: rgb(91, 97, 91);
    border-color: rgb(56, 54, 54);
    border-width: 1px;
    border-style: solid;
    touch-action: none;
    box-sizing: border-box;
    color: black;
}

