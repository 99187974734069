.Muelle {
    background-color: rgb(161, 30, 37);
    border-style: solid;
    border-color: black;
    border-width: 1px;
    color: white;
}

.muelle-seleccionado { 
    border-style: solid;
    border-width: 3px;
    border-color: rgb(252, 227, 7);
}

.Muelle:hover { 
    box-shadow: 1px 3px;
}

.Muelle {
    position: absolute;
}