.divisiones-col {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0%;
  }

.ubicacion {
  font-size: 5px;
  text-align: center;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
}
