.bloque-seleccionado { 
    border-style: solid;
    border-width: 2px;
    border-color: coral;
}

.Bloque:hover { 
    box-shadow: 1px 3px;
}

.Bloque {
    position: absolute;
}